<template>
  <div class="flex-column" v-loading="loading">
    <sticky :className="'sub-navbar'">
      <div class="filter-container">
        <base-search :searchList="$bbglCbjlSearch" :listQuery='listQuery' v-on:search='handleFilter' v-on:btn-event="onBtnClicked" style="display: inline-block;"  :dynamic='true' @switchChange="switchChange"></base-search>
      </div>
    </sticky>
    <div class="app-container flex-item">
      <!-- 拖拽div -->
      <div class="dragBox" ref="dragBox">
        <div class="dragBox_left">
          <!--左侧div内容-->
          <el-card shadow="never" class="body-small" style="height: 100%;overflow:auto;">
            <basic-tree :isShowCheck="true" @handleNodeClick="handleNodeClick" @getCheckedKeys="getCheckedKeys" @nodeClickOne="nodeClickOne" :defaultChoose="defaultChoose"></basic-tree >
          </el-card>
        </div>
        <div class="dragBox_resize" title="收缩侧边栏">
          ⋮
        </div>
        <div class="dragBox_mid">
          <!--右侧div内容-->
          <div class="bg-white" style="height: 100%;" id="table_box_height">
            <basic-table :tableHeight="tableHeight" :tableHeaderList="firstHeaderList" :tableData="tableData" :listQuery="listQuery" @pagination="handleCurrentChange" @tableRowClick="tableRowClick" :tableTotal="tableTotal" @tableColumnClick="tableColumnClick"></basic-table >
          </div>
        </div>
      </div>
      <!-- 拖拽div -->
    </div>
  </div>

</template>

<script>
  import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口
  
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import waves from '@/directive/waves' // 水波纹指令
  import Sticky from '@/components/Sticky'
  import elDragDialog from '@/directive/el-dragDialog'
  import { parseTime } from '@/utils/index'
  export default {
    name: 'hdybjzqgl',
    components: {
      Sticky,
    },
    directives: {
      waves,
      elDragDialog
    },
    data() {
      return {
        loading:false,//遮罩
        listQuery: { // 查询条件
          page: 1,
          limit: 50,
          queryType: '0',
          queryKey:'UserNumber',
          queryValue: '',
          TreeIds:[],
          beginTime:'',
          endTime:'',
          dateScope:'',
          isTotalMeter:this.$store.state.isTotalMeter,//是否是总表查询
        },
        orgs: [], // 用户可访问到的组织列表
        tableHeaderList:this.$store.state.headerList,
        tableData:[],
        tableHeight:500,//表格高度
        CheckedKey:[],//选中数据的id集合
        tableChooseRow:{},//table选中数据
        firstHeaderList:[],// 主列表列定义
        tableTotal:0,//明细条数
        excelName: `抄表记录${parseTime(new Date())}`,
        defaultChoose:[],//通过跳转过来的页面默认选中树
      }
    },
    watch: {
      
    },
    computed: {
      // orgsTree() {
      //   return this.$store.state.AllArea;
      // },
      json_fields() {//导出主表数据处理
        let obj = {}
        this.firstHeaderList.length > 0 && this.firstHeaderList.forEach(item => {
          if (!item.comment) return
          if (item.key === 'orderType' || item.key === 'goodsType' || item.key === 'transferType') {
            obj[item.comment] = {
              field: item.key,
              callback: value => {
                return this.handleFilterState(value, item.key)
              }
            }
            return
          }
          obj[item.comment] = item.key
        })
        return obj
      }
    },
    filters: {
      
    },
    created() {
      
    },
    mounted() {
      if(this.$route.query.areaId){//如果是页面跳转
        this.listQuery.TreeIds = []
        this.listQuery.TreeIds.push(this.$route.query.areaId)
        this.defaultChoose = []
        this.defaultChoose.push(this.$route.query.areaId)
        
      }
      this.getList()

      // 计算table高度
      this.$nextTick(()=>{
        let ele=document.getElementById("table_box_height");
        this.tableHeight=ele.offsetHeight-50
        window.addEventListener('resize', () => { 
          let ele=document.getElementById("table_box_height");
          this.tableHeight=ele.offsetHeight-50
          this.$forceUpdate()
        });
      })
      
      this.$basicFun.dragControllerDiv();//拖拽div方法
    },
    methods: {
      // swith开关切换
      switchChange(e){
        var data={
          key:'isTotalMeter',
          value:e,
          userId:this.$store.state.userId
        }
        basicDataApi.saveisTotalMeter(data).then(response => {
          if(response.code == 200){
            localStorage.setItem('isTotalMeter', e);
            this.$store.state.isTotalMeter = e
            this.getList()
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        }).catch(()=>{})
      },
      // 节点点击
      handleNodeClick(data) {
        this.listQuery.TreeIds = data
      },
      // 添加节点选中数据
      getCheckedKeys(data){
        console.log(data)
      },
      //节点点击
      nodeClickOne(data){
        this.listQuery.TreeIds = []
        this.listQuery.TreeIds.push(data.id)
        this.getList()
      },
      // 按钮点击
      onBtnClicked: function(domId, callback) {
        console.log('you click:' + domId)
        switch (domId) {
          case 'btnDel'://删除
            this.handleDelete(this.tableChooseRow)
            break
          case 'btnAdd'://添加
            
            break
          case 'btnEdit'://编辑
            
            break
          case 'btnExport':  //导出
            this.handleDownExcel(callback)
            break;
          
          default:
            break
        }
      },
      // 导出
      handleDownExcel(callback) {
        // 前端导出
        // let obj = {}
        // obj.json_fields = this.json_fields//导出主表
        // obj.data = this.tableData//导出主表
        // // obj.json_fields = this.detials_json_fields//导出子表
        // // obj.data = this.multipleSelection.length==0?this.secondList:this.multipleSelection         //导出子表
        // obj.excelName = `抄表记录${parseTime(new Date())}`
        // callback(obj)
        if(this.listQuery.dateScope){
          this.listQuery.beginTime = this.$basicFun.dataFormat(this.listQuery.dateScope[0],'yyyy-MM-dd');
          this.listQuery.endTime = this.$basicFun.dataFormat(this.listQuery.dateScope[1],'yyyy-MM-dd');
        }
        basicDataApi.cbjlExport(this.listQuery).then(response => {
          if(response.code == 200){
            var url = process.env.VUE_APP_BASE_IMG_URL + '/'+ response.result
            window.location.href = url
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
          this.loading = false
        })
      },
      // 导出状态转换
      handleFilterState(val, key) {
        const typeIds = {
          orderType: 'SYS_INBOUNDTYPE',
          goodsType: 'SYS_GOODSTYPE',
          transferType: 'SYS_SHIPTYPE',
          status: 'SYS_ORDERSTATUS',//单据状态转换
          externalType:'SYS_EXTERNALTYPE',
        }
        const obj = this.typeDataLists.length > 0 && this.typeDataLists.find(item => item.typeId === typeIds[key])
        const arr = obj && obj.typeDatas || []
        const item = arr.length > 0 && arr.find(item => item.dtCode === val) && arr.find(item => item.dtCode === val).name || ''
        return item || val || ''
      },
      // 导出状态转换-单据状态转换
      handleFilterRderstayus(val,key){
        const arr = key === 'status' ? this.$store.state.rderstayus : ''
        return key === 'asnStatus' ? val === 0 ? '否' : '是' : arr.find(item => item.dtCode === val).name
      },
      // 获取数据
      getList() {
        this.loading = true
        if(this.listQuery.dateScope){
          this.listQuery.beginTime = this.$basicFun.dataFormat(this.listQuery.dateScope[0],'yyyy-MM-dd');
          this.listQuery.endTime = this.$basicFun.dataFormat(this.listQuery.dateScope[1],'yyyy-MM-dd');
        }
        basicDataApi.cbjlGetList(this.listQuery).then(response => {
          if(response.code == 200){
            response.columnHeaders.forEach((item) => { // 首字母小写
              item.key = item.key.substring(0, 1).toLowerCase() + item.key.substring(1)
            })
            this.firstHeaderList = response.columnHeaders.filter(u => u.browsable && u.isList)
            this.firstHeaderList.forEach(i=>{
              i.columnWidth = 160
            })
            this.tableData = response.data
            this.tableTotal = response.count
          }else{
            this.firstHeaderList = []
            this.tableData = []
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
          this.loading = false
        }).catch(()=>{
          this.firstHeaderList = []
          this.tableData = []
          this.loading = false
        })
      },
      // 搜索
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
      // 切换分页
      handleCurrentChange(val) {
        this.tableChooseRow = {}//重置选中项
        this.listQuery.page = val.page
        this.listQuery.limit = val.limit
        this.getList()
      },
      // 行点击
      tableRowClick(row){
        this.tableChooseRow = row
      },
      handleDelete(row) { // 多行删除
        this.tableData.splice(this.tableData.findIndex(e=>e.serialNumber === row.serialNumber),1)
      },
      //点击跳转列
      tableColumnClick(row){
        this.$router.push({path: '/hdybybzl/index',query:{ surfaceNum:row.surfaceNum}});
      },
    },
  }

</script>

<style lang="scss" scoped>
  
</style>
